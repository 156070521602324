<template>
  <section>
    <CustomCheckboxComponent
      ref="checkbox"
      :label="label ? 'J\'autoconsomme tout : ' : 'Tout : '"
      :labelCols="labelCols"
      :disabled="readOnly"
      :checked="enTotalite"
      @input="(value) => $emit('update:enTotalite', value)"
    ></CustomCheckboxComponent>
    <template v-if="!enTotalite">
      <CustomInputNumberComponent
        ref="inputQuantite"
        :label="label"
        :labelCols="labelCols"
        :append="label ? 't' : undefined"
        :readOnly="readOnly"
        :quickEdit="quickEdit"
        :rules="`required|min_value:0.1|max_value:${quantiteProduite}`"
        :value="value"
        @input="(value) => $emit('input', value)"
        @change="(value) => $emit('change', value)"
      ></CustomInputNumberComponent>
    </template>
  </section>
</template>

<script>
import CustomCheckboxComponent from "./CustomCheckboxComponent.vue";
import CustomInputNumberComponent from "./CustomInputNumberComponent.vue";
export default {
  name: "InputQuantiteAutoconsoComponent",
  components: {
    CustomCheckboxComponent,
    CustomInputNumberComponent,
  },
  /**
   * enTotalite = valeur du switch "Tout"
   * value = valeur de la quantité autoconso
   * label = label du form group quantité autoconso
   * labelCols = labelCols du form group quantité autoconso
   * readOnly = conditionne l'affichage en lecture seule (false par défaut)
   * quickEdit = indique si prend en charge l'event "edit" (false par défaut)
   * quantiteProduite = quantité max autorisée en autoconso
   */
  props: [
    "enTotalite",
    "value",
    "label",
    "labelCols",
    "readOnly",
    "quickEdit",
    "quantiteProduite",
  ],
  methods: {
    focus() {
      this.$refs.checkbox.focus();
    },
    async validate() {
      await this.$refs.inputQuantite?.validate();
    },
  },
};
</script>
