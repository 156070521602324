<template>
  <b-form-group
    :id="`group-${id}`"
    :label="label"
    :label-for="`input-${id}`"
    :label-cols="computedLabelCols['xs']"
    :label-cols-sm="computedLabelCols['sm']"
    :label-cols-md="computedLabelCols['md']"
    :label-cols-lg="computedLabelCols['lg']"
    :label-cols-xl="computedLabelCols['xl']"
    :description="description"
  >
    <b-input-group :prepend="prepend" :append="append">
      <template v-if="!!$slots.prepend" #prepend>
        <slot name="prepend"></slot>
      </template>
      <b-form-input
        ref="input"
        :id="`input-${id}`"
        :name="`input-${id}`"
        type="number"
        :size="size"
        :number="true"
        :step="step"
        :disabled="computedDisabled"
        :value="computedValue"
        @input="(value) => $emit('input', sanitizeNumberValue(value))"
        @change="(value) => $emit('change', sanitizeNumberValue(value))"
      ></b-form-input>
      <template v-if="!!$slots.append" #append>
        <slot name="append"></slot>
      </template>
    </b-input-group>
  </b-form-group>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import {
  computed as inputsComputed,
  methods as inputsMethods,
} from "../../services/inputs.service";
export default {
  name: "UnvalidatedInputNumberComponent",
  props: {
    // valeur de l'input
    value: Number,
    // step de l'input
    step: {
      type: [Number, String],
      default: 0.1,
    },
    // prepend de l'input group
    prepend: String,
    // append de l'input group
    append: String,
    // propriété label du form-group
    label: {
      type: String,
      required: true,
    },
    // propriété label-cols du form group
    labelCols: [String, Number, Object],
    // propriété size du contrôle
    size: String,
    // propriété label-cols du form group
    description: String,
    // true pour griser le controle (false par défaut)
    disabled: [Boolean, String],
  },
  data: () => ({
    id: uuidv4(),
  }),
  computed: {
    ...inputsComputed,
    computedValue() {
      return this.value == null ? null : Math.round(this.value * 100.0) / 100.0;
    },
  },
  methods: {
    ...inputsMethods,
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>
