<template>
  <section>
    <TitleBar tabsParent="recolte">
      Campagne {{ $route.params.codeRecolte }}
      <template #toolbar-content>
        <b-button-group v-if="presaisieAutorisee">
          <b-button pill variant="primary" @click="initAutoconso()">
            <b-icon-check-all></b-icon-check-all>
            Tout pr&eacute;remplir
          </b-button>
        </b-button-group>
        &nbsp;
        <TableButtonGroup
          :tableKey="tableKey"
          @rechercher="rechercher"
        ></TableButtonGroup>
        &nbsp;
        <ExportButtonGroup
          @imprimer="() => {}"
          @exportExcel="() => {}"
          @exportCsv="() => {}"
        ></ExportButtonGroup>
      </template>
    </TitleBar>
    <b-container fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <PaginatedTable
        :fields="fields"
        :busy="busy"
        :pageable="pageable"
        :page="page"
        @change="pageableChanged"
        @keydown.native.esc="load"
        @keydown.native.enter="onEnter"
      >
        <template #mobile-card="{ item }">
          <AutoconsoCard
            :item="item"
            @view="openAutoconsoCultureEditor(item, true)"
            @viewEmb="(emb) => openAutoconsoEmblavementEditor(item, emb, true)"
          ></AutoconsoCard>
        </template>
        <template #cell(expand)="row">
          <ToggleRowComponent :row="row" @toggle="toggleCulture(row)">
            Afficher les parcelles
          </ToggleRowComponent>
        </template>
        <template #row-details="{ item }">
          <AutoconsoDetailsComponent
            :culture="item"
            :confirmationProvider="confirmSaisieEmblavement"
            @cancel="load"
            @validate="autoconsoChanged"
            @comment="openCommentaireEmblavementEditor"
            @view="(emb) => openAutoconsoEmblavementEditor(item, emb, true)"
            @edit="(emb) => openAutoconsoEmblavementEditor(item, emb, false)"
          ></AutoconsoDetailsComponent>
        </template>
        <template #cell(libelleProduit)="{ item }">
          {{ item.libelleProduit }}
          <MelangeIcon v-if="item.isMelangeEspeces">
            M&eacute;lange d'esp&egrave;ces
          </MelangeIcon>
        </template>
        <template #cell(libelleVariete)="{ item }">
          {{ item.libelleVariete }}
          <MelangeIcon v-if="item.isMelangeVarietes">
            M&eacute;lange de vari&eacute;t&eacute;s
          </MelangeIcon>
        </template>
        <template #cell(quantiteProduite)="{ item }">
          <span :id="`quantite-culture-${item.id}`">
            <MontantComponent :value="item.quantiteProduite"></MontantComponent>
          </span>
          <QuantiteCollecteTooltip
            :target="`quantite-culture-${item.id}`"
            :quantitePrevisionnelle="item.quantitePrevisionnelle"
            :quantiteMoissonnee="item.quantiteMoissonnee"
            :quantiteAutoconsommee="item.quantiteAutoconsommee"
          ></QuantiteCollecteTooltip>
        </template>
        <template #cell(motifAutoconso)="{ item }">
          <SelectMotifComponent
            :ref="`selectMotif_${item.id}`"
            label=""
            :readOnly="editing !== item.id"
            :societe="exploitationCourante.codeSociete"
            rules="required"
            v-model="item.autoconso.codeMotif"
          >
            <BadgeComponent
              v-if="item.autoconso.id"
              :text="item.autoconso.motif.libelle"
              :variant="`list${item.autoconso.motif.ordre}`"
              @click="editCulture(item)"
            ></BadgeComponent>
            <b-link v-else href="#" @click="editCulture(item)">
              <AnomalieIcon
                :anomaliesCulture="item.anomalies"
                :anomaliesGroupement="item.anomalieEmblavements"
              ></AnomalieIcon>
              <em v-if="item.saisieEmblavements">
                Autoconso &agrave; la parcelle
              </em>
              <span v-else>Saisir une autoconso</span>
            </b-link>
          </SelectMotifComponent>
        </template>
        <template #cell(quantiteAutoconsommee)="{ item }">
          <template v-if="item.autoconso.codeMotif === 'NONE'">&nbsp;</template>
          <template v-else-if="editing !== item.id">
            <MontantComponent
              v-if="item.saisieEmblavements"
              :value="item.quantiteAutoconsommee"
            ></MontantComponent>
            <template v-else-if="!item.autoconso.id">&nbsp;</template>
            <template v-else>
              <AnomalieIcon
                v-if="item.autoconso.anomalies.includes('QUANTITE_INVALIDE')"
                classes="mr-3"
                :anomaliesAutoconso="['QUANTITE_INVALIDE']"
              ></AnomalieIcon>
              <b-link
                class="quick-edit-link"
                href="#"
                @click="editCulture(item)"
              >
                <span v-if="item.autoconso.enTotalite === true">Tout</span>
                <MontantComponent
                  v-else
                  :value="item.autoconso.quantiteAutoconsommee"
                ></MontantComponent>
              </b-link>
            </template>
          </template>
          <InputQuantiteAutoconsoComponent
            v-else
            :ref="`inputQuantite_${item.id}`"
            label=""
            :enTotalite="item.autoconso.enTotalite"
            @update:enTotalite="item.autoconso.enTotalite = $event"
            :quantiteProduite="item.quantiteProduite"
            v-model="item.autoconso.quantiteAutoconsommee"
          ></InputQuantiteAutoconsoComponent>
        </template>
        <template #cell(actions)="{ item }">
          <TableActionsComponent
            :editing="editing === item.id"
            :commentValue="item.autoconso ? item.autoconso.commentaire : null"
            :commentDisabled="!item.autoconso || !item.autoconso.id"
            :noDropdown="true"
            @cancel="load"
            @validate="validate(item)"
            @comment="openCommentaireCultureEditor(item)"
            @view="openAutoconsoCultureEditor(item, true)"
            @edit="openAutoconsoCultureEditor(item)"
          ></TableActionsComponent>
        </template>
        <template #pagination>
          Quantit&eacute; autoconsomm&eacute;e :
          <strong>
            <MontantComponent :value="quantiteAutoconsommee"></MontantComponent>
            t
          </strong>
        </template>
      </PaginatedTable>
      <AutoconsoEditionDialog ref="autoconsoEditionDialog">
      </AutoconsoEditionDialog>
      <CommentaireDialog ref="commentaireEditionDialog"></CommentaireDialog>
      <ConfirmationDialog ref="confirmationDialog"></ConfirmationDialog>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import TableButtonGroup from "../../components/controls/TableButtonGroup.vue";
import ExportButtonGroup from "../../components/controls/ExportButtonGroup.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import PaginatedTable from "../../components/PaginatedTable.vue";
import AutoconsoCard from "../../components/cards/AutoconsoCard.vue";
import ToggleRowComponent from "../../components/controls/ToggleRowComponent.vue";
import MontantComponent from "../../components/MontantComponent.vue";
import QuantiteCollecteTooltip from "../../components/controls/QuantiteCollecteTooltip.vue";
import AutoconsoDetailsComponent from "../../components/AutoconsoDetailsComponent.vue";
import BadgeComponent from "../../components/BadgeComponent.vue";
import AnomalieIcon from "../../components/controls/AnomalieIcon.vue";
import SelectMotifComponent from "../../components/inputs/SelectMotifComponent.vue";
import InputQuantiteAutoconsoComponent from "../../components/inputs/InputQuantiteAutoconsoComponent.vue";
import TableActionsComponent from "../../components/controls/TableActionsComponent.vue";
import AutoconsoEditionDialog from "../../components/dialogs/AutoconsoEditionDialog.vue";
import CommentaireDialog from "../../components/dialogs/CommentaireDialog.vue";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog.vue";
import { TableKeys } from "../../store/tables.defaults";
import {
  dataFactory as tableDataFactory,
  created,
  computed as tableComputed,
  methods as tableMethods,
} from "../../services/tables.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "AutoconsoView",
  components: {
    TitleBar,
    TableButtonGroup,
    ExportButtonGroup,
    ErrorComponent,
    PaginatedTable,
    AutoconsoCard,
    ToggleRowComponent,
    MontantComponent,
    QuantiteCollecteTooltip,
    AutoconsoDetailsComponent,
    BadgeComponent,
    AnomalieIcon,
    SelectMotifComponent,
    InputQuantiteAutoconsoComponent,
    TableActionsComponent,
    AutoconsoEditionDialog,
    CommentaireDialog,
    ConfirmationDialog,
  },
  data() {
    return {
      ...tableDataFactory(TableKeys.AUTOCONSOMMATION),
      error: null,
      page: null,
      culturesOuvertes: [],
      editing: null,
    };
  },
  computed: {
    ...tableComputed,
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    presaisieAutorisee() {
      return (
        // FIXME this.$store.state.decl.autoconso.length === 0 &&
        this.$store.state.decl.emblavements.length > 0
      );
    },
    quantiteAutoconsommee() {
      return this.$store.getters["decl/quantiteAutoconsommee"];
    },
  },
  created,
  async mounted() {
    this.load();
  },
  methods: {
    ...tableMethods,
    load() {
      try {
        this.busy = true;
        this.editing = null;

        // Récupérer les données dans le store
        let cultures = UtilsService.deepCopy(this.$store.state.decl.cultures)
          .map((c) => {
            // Récupérer les emblavements
            c.emblavements = UtilsService.deepCopy(
              this.$store.state.decl.parcelles
                .filter((p) => p.idCulture === c.id)
                .flatMap((p) =>
                  this.$store.getters["decl/emblavementsFromParcelle"](p.id)
                )
            )
              .map((e) => {
                // Récupérer les infos ilot/parcelle
                e.parcelle = this.$store.getters["decl/parcelle"](e.idParcelle);
                e.parcelle.ilot = this.$store.getters["decl/ilot"](
                  e.parcelle.idIlot
                );
                e.parcelle.libelleParcelle = `${e.parcelle.ilot.libelle} - ${e.parcelle.libelle}`;

                // Récupérer les infos produit/variété
                e.produit = this.$store.getters["ref/produitCereale"](
                  e.codeProduit
                );
                e.variete = this.$store.getters["ref/variete"](e.codeVariete);
                e.espece = this.$store.getters["ref/espece"](
                  e.produit?.codeEspece
                );

                // Récupérer l'autoconso
                e.autoconso = UtilsService.deepCopy(
                  this.$store.getters["decl/autoconsoFromEmblavement"](e.id)
                ) || {
                  idEmblavement: e.id,
                  codeMotif: null,
                  enTotalite: false,
                  quantiteAutoconsommee: 0.0,
                  anomalies: [],
                };
                e.autoconso.motif = this.$store.getters["ref/motifAutoconso"](
                  e.autoconso.codeMotif
                );

                // Calculer les quantités
                if (e.quantiteMoissonnee != null) {
                  e.quantiteProduite = e.quantiteMoissonnee;
                  e.quantitePrevisionnelle = null;
                } else {
                  e.quantiteProduite = e.quantitePrevisionnelle;
                }
                return e;
              })
              .sort(
                UtilsService.sortBy(
                  UtilsService.sortByStringProperty("parcelle.libelleParcelle"),
                  UtilsService.sortByStringProperty("espece.libelle")
                )
              );

            // Récupérer l'autoconso
            c.autoconso = UtilsService.deepCopy(
              this.$store.getters["decl/autoconsoFromCulture"](c.id)
            ) || {
              idCulture: c.id,
              codeMotif: null,
              enTotalite: false,
              quantiteAutoconsommee: 0.0,
              anomalies: [],
            };
            c.autoconso.motif = this.$store.getters["ref/motifAutoconso"](
              c.autoconso.codeMotif
            );

            // Calculer les quantités
            c.quantitePrevisionnelle = c.emblavements.reduce(
              (acc, next) => acc + (next.quantitePrevisionnelle ?? 0.0),
              0.0
            );
            c.quantiteMoissonnee = c.emblavements.reduce(
              (acc, next) => acc + (next.quantiteMoissonnee ?? 0.0),
              0.0
            );
            c.quantiteProduite =
              c.quantitePrevisionnelle + c.quantiteMoissonnee;
            c.quantiteAutoconsommee =
              c.autoconso.quantiteAutoconsommee +
              c.emblavements.reduce(
                (acc, next) => acc + next.autoconso.quantiteAutoconsommee,
                0.0
              );

            // Calculer les statuts à l'avance
            c.saisieEmblavements = !!c.emblavements.find(
              (e) => !!e.autoconso.id
            );
            c.anomalieEmblavements = !!c.emblavements.find(
              (e) => e.autoconso.anomalies.length > 0
            );

            // Ouvrir les autoconso à la parcelle
            c._showDetails = this.culturesOuvertes.includes(c.id);
            return c;
          })
          .filter((c) => c.emblavements.length > 0);

        // Filtrer la liste
        cultures = cultures.filter(this.getFilterFunction());

        // Trier et paginer le résultat
        this.page = UtilsService.readOnlyPage(
          cultures,
          this.pageable.currentPage - 1,
          this.pageable.perPage,
          this.getSortFunction()
        );
      } catch (error) {
        this.page = null;
        this.error = UtilsService.handleError(error);
      } finally {
        this.busy = false;
      }
    },
    async initAutoconso() {
      let confirmation = await this.$refs.confirmationDialog.show({
        title: "Préremplir l'autoconsommation ?",
        messages: [
          `Souhaitez-vous présaisir "Pas d'autoconsommation" pour toutes les cultures ?`,
          `Cette saisie s'appliquera à toutes les cultures actuellement sans autoconsommation et pour lesquelles la saisie d'une autoconsommation est demandée.`,
        ],
        validate: "Oui",
        cancel: "Non",
      });
      if (confirmation) {
        this.$store
          .dispatch("decl/initAutoconso", {
            codeRecolte: this.$route.params.codeRecolte,
            codeTiers: this.$store.getters["expl/adherentCourant"]?.codeTiers,
            codeMotif: "NONE",
            enTotalite: false,
            quantiteAutoconsommee: 0.0,
          })
          .catch((e) => (this.error = UtilsService.handleError(e)))
          .finally(() => this.load());
      }
    },
    toggleCulture(row) {
      if (row.item._showDetails) {
        this.culturesOuvertes = this.culturesOuvertes.filter(
          (id) => id !== row.item.id
        );
      } else {
        this.culturesOuvertes.push(row.item.id);
      }
      row.toggleDetails();
    },
    async openCommentaireCultureEditor(culture) {
      let resultat = await this.$refs.commentaireEditionDialog.show({
        labelItems: "Culture",
        items: [
          culture.isMelangeEspeces
            ? culture.libelleProduit
            : `${culture.libelleProduit} - ${culture.libelleVariete}`,
        ],
        labelCommentaire: "Commentaire autoconsommation",
        description:
          "Utilisez cet espace pour noter ce que vous voulez concernant votre autoconsommation.",
        commentaire: culture.autoconso.commentaire,
      });
      if (resultat != null) {
        culture.autoconso.commentaire = resultat;
        this.autoconsoChanged(culture.autoconso);
      }
    },
    async openCommentaireEmblavementEditor(emblavement) {
      let resultat = await this.$refs.commentaireEditionDialog.show({
        labelItems: "Emblavement",
        items: [
          `Parcelle : ${emblavement.parcelle.libelleParcelle}`,
          `Culture : ${
            emblavement.semeEnMelangeEspeces
              ? emblavement.produit.libelle
              : `${emblavement.produit.libelle} - ${emblavement.variete.libelle}`
          }`,
        ],
        labelCommentaire: "Commentaire autoconsommation",
        description:
          "Utilisez cet espace pour noter ce que vous voulez concernant votre autoconsommation.",
        commentaire: emblavement.autoconso.commentaire,
      });
      if (resultat != null) {
        emblavement.autoconso.commentaire = resultat;
        this.autoconsoChanged(emblavement.autoconso);
      }
    },
    async confirmSaisieCulture(culture) {
      // Si on ne trouve aucune autoconso sur les emblavements
      if (!culture.emblavements.find((e) => !!e.autoconso.id)) {
        return true;
      }
      // Sinon on demande confirmation avant d'écraser
      return await this.$refs.confirmationDialog.show({
        title: "Définir une autoconsommation à la culture ?",
        messages: [
          `Vous avez défini une autoconsommation à la parcelle, êtes-vous sûr.e de vouloir définir une autoconsommation à la culture ?`,
          `L'autoconsommation à la culture remplacera les autoconsommations à la parcelle, qui seront supprimées.`,
        ],
      });
    },
    async openAutoconsoCultureEditor(culture, readOnly) {
      let parent = this;
      this.autoconsoChanged(
        await this.$refs.autoconsoEditionDialog.show({
          culture,
          readOnly,
          confirmationProvider: async function () {
            return await parent.confirmSaisieCulture(culture);
          },
        })
      );
    },
    async confirmSaisieEmblavement(culture) {
      // Si on ne trouve aucune autoconso sur la culture
      if (!culture.autoconso.id) {
        return true;
      }
      // Sinon on demande confirmation avant d'écraser
      return await this.$refs.confirmationDialog.show({
        title: "Définir une autoconsommation à la parcelle ?",
        messages: [
          `Vous avez défini une autoconsommation à la culture, êtes-vous sûr.e de vouloir définir une autoconsommation à la parcelle ?`,
          `L'autoconsommation actuelle à la culture sera supprimée et vous devrez saisir une autoconsommation sur chaque parcelle.`,
        ],
      });
    },
    async openAutoconsoEmblavementEditor(culture, emblavement, readOnly) {
      let parent = this;
      this.autoconsoChanged(
        await this.$refs.autoconsoEditionDialog.show({
          emblavement,
          readOnly,
          confirmationProvider: async function () {
            return await parent.confirmSaisieEmblavement(culture);
          },
        })
      );
    },
    async editCulture(culture) {
      if (await this.confirmSaisieCulture(culture)) {
        this.editing = culture.id;
        if (!culture.id) {
          return;
        }
        await this.$nextTick();
        this.$refs[`inputQuantite_${culture.id}`]?.focus();
        this.$refs[`selectMotif_${culture.id}`]?.focus();
      }
    },
    async onEnter() {
      let target = this.page.content.find((t) => t.id === this.editing);
      if (target) {
        this.validate(target);
      }
    },
    autoconsoChanged(autoconso) {
      if (autoconso) {
        this.$store
          .dispatch("decl/createUpdateAutoconso", autoconso)
          .catch((e) => (this.error = UtilsService.handleError(e)))
          .finally(() => this.load());
      }
    },
    async validate(culture) {
      await this.$refs[`selectMotif_${culture.id}`].validate();
      await this.$refs[`inputQuantite_${culture.id}`]?.validate();
      this.autoconsoChanged(culture.autoconso);
    },
  },
};
</script>
