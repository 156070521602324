var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`culture_${_vm.item.id}_collapse culture_${_vm.item.id}_button`),expression:"`culture_${item.id}_collapse culture_${item.id}_button`"}],staticClass:"mobile-card",attrs:{"header-bg-variant":"light"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.item.libelleProduit)+" "),(_vm.item.isMelangeEspeces)?_c('MelangeIcon'):_c('div',{staticClass:"label font-down"},[_vm._v(" "+_vm._s(_vm.item.libelleVariete)+" "),(_vm.item.isMelangeVarietes)?_c('MelangeIcon'):_vm._e()],1)]},proxy:true}])},[_c('b-container',[_c('b-row',{attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"bg-light bg-left font-up",staticStyle:{"width":"80px","border-radius":"0 !important"},attrs:{"cols":"auto"}},[_c('div',{staticClass:"w-100"},[_c('MontantComponent',{attrs:{"value":_vm.quantiteAutoconsoCulture,"options":{
              minimumFractionDigits: undefined,
              maximumFractionDigits: 2,
            }}}),_vm._v(" t ")],1)]),_c('b-col',{staticClass:"font-down"},[_c('div',[_c('AnomalieIcon',{attrs:{"anomaliesCulture":_vm.item.anomalies,"anomaliesAutoconso":_vm.item.autoconso.anomalies}}),(_vm.saisieCulture)?_c('BadgeComponent',{attrs:{"text":_vm.item.autoconso.motif.libelle,"variant":`list${_vm.item.autoconso.motif.ordre}`}}):(_vm.saisieEmblavement)?_c('em',[_vm._v(" Autoconso à la parcelle ")]):[_vm._v("Saisir une autoconso")]],2),_c('div',{staticClass:"label"},[_c('MontantComponent',{attrs:{"value":_vm.item.quantiteProduite}}),_vm._v(" t disponibles ")],1),_c('b-collapse',{staticClass:"label text-center",attrs:{"id":`culture_${_vm.item.id}_button`,"visible":""}},[_c('b-icon-chevron-down'),_vm._v(" Voir les parcelles ")],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"cols":"auto"},on:{"click":function($event){$event.stopPropagation();}}},[_c('ViewEditComponent',{on:{"view":function($event){return _vm.$emit('view')}}})],1)],1),_c('b-collapse',{staticStyle:{"border-top":"1px solid lightgrey"},attrs:{"id":`culture_${_vm.item.id}_collapse`}},_vm._l((_vm.item.emblavements),function(emb){return _c('b-row',{key:emb.id,attrs:{"align-v":"center"}},[_c('b-col',{staticClass:"bg-light bg-left font-up",staticStyle:{"width":"80px","border-radius":"0 !important"},attrs:{"cols":"auto"}},[(_vm.saisieEmblavement)?_c('div',{staticClass:"w-100"},[_c('MontantComponent',{attrs:{"value":emb.autoconso.enTotalite
                  ? emb.quantiteProduite
                  : emb.autoconso.quantiteAutoconsommee,"options":{
                minimumFractionDigits: undefined,
                maximumFractionDigits: 2,
              }}}),_vm._v(" t ")],1):_vm._e()]),_c('b-col',{staticClass:"py-0 pt-1 font-down"},[_c('div',{staticClass:"rounded bg-light"},[_vm._v(" "+_vm._s(emb.parcelle.libelleParcelle)+" ("),_c('MontantComponent',{attrs:{"value":emb.quantiteProduite}}),_vm._v(" t) ")],1),(_vm.item.isMelangeEspeces)?_c('div',[_vm._v(" "+_vm._s(emb.produit.libelle)+" ")]):(_vm.item.isMelangeVarietes)?_c('div',[_vm._v(" "+_vm._s(emb.variete.libelle)+" "),_c('MelangeIcon')],1):_vm._e(),_c('div',[(_vm.saisieCulture)?_c('em',[_vm._v("Autoconso à la culture")]):(emb.autoconso.id)?_c('BadgeComponent',{attrs:{"text":emb.autoconso.motif.libelle,"variant":`list${emb.autoconso.motif.ordre}`}}):[_vm._v("Saisir une autoconso")]],2)]),_c('b-col',{staticClass:"px-0",attrs:{"cols":"auto"},on:{"click":function($event){$event.stopPropagation();}}},[_c('ViewEditComponent',{on:{"view":function($event){return _vm.$emit('viewEmb', emb)}}})],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }