<template>
  <b-modal
    ref="modal"
    scrollable
    @shown="$refs.selectMotif?.focus()"
    @close="cancel"
  >
    <template #modal-title>
      <template v-if="readOnly">Mon autoconsommation</template>
      <template v-if="!readOnly">Modifier mon autoconsommation</template>
    </template>
    <b-container class="context-container">
      <!--
      <GenericReadonlyComponent
        v-if="emblavement"
        label="Parcelle : "
        labelCols="auto"
        :value="emblavement.parcelle.libelleParcelle"
      ></GenericReadonlyComponent>
      -->
      <GenericReadonlyComponent
        label="Culture : "
        labelCols="auto"
        :value="libelleProduit"
      ></GenericReadonlyComponent>
      <GenericReadonlyComponent
        label="Variété : "
        labelCols="auto"
        :value="libelleVariete"
      ></GenericReadonlyComponent>
      <!--
      <CustomInputNumberComponent
        label="Surface totale :"
        labelCols="auto"
        append="ha"
        :readOnly="true"
        :value="surfaceCumulee"
      ></CustomInputNumberComponent>
      <CustomInputNumberComponent
        label="Rendement moyen :"
        labelCols="auto"
        append="t/ha"
        :readOnly="true"
        :value="rendementMoyen"
      ></CustomInputNumberComponent>
      <CustomInputNumberComponent
        label="Quantité produite :"
        labelCols="auto"
        append="t"
        :readOnly="true"
        :value="quantiteProduite"
      ></CustomInputNumberComponent>
      -->
    </b-container>
    <template v-if="autoconso">
      <p>
        Vous avez produit ou projetez de produire
        <strong>
          <MontantComponent :value="quantiteProduite"></MontantComponent>
          t</strong
        >
        pour une surface totale de
        <strong>
          <MontantComponent :value="surfaceCumulee"></MontantComponent>
          ha</strong
        >, soit un rendement moyen de
        <strong>
          <MontantComponent :value="rendementMoyen"></MontantComponent>
          t/ha</strong
        >.
      </p>
      <template v-if="autoconso.codeMotif || !readOnly">
        <SelectMotifComponent
          ref="selectMotif"
          label="Motif autoconso :"
          :labelCols="{ md: 5 }"
          :readOnly="readOnly"
          :societe="exploitationCourante.codeSociete"
          rules="required"
          v-model="autoconso.codeMotif"
        ></SelectMotifComponent>
        <InputQuantiteAutoconsoComponent
          v-if="autoconso.codeMotif !== 'NONE'"
          ref="inputQuantite"
          :enTotalite="autoconso.enTotalite"
          @update:enTotalite="autoconso.enTotalite = $event"
          label="Quantité autoconso :"
          :labelCols="{ md: 5 }"
          :readOnly="readOnly"
          :quantiteProduite="quantiteProduite"
          v-model="autoconso.quantiteAutoconsommee"
        ></InputQuantiteAutoconsoComponent>
        <InputCommentaireComponent
          label="Commentaire autoconso :"
          :readOnly="readOnly"
          v-model="autoconso.commentaire"
        ></InputCommentaireComponent>
        <p v-if="!readOnly">
          <em>
            Vous pouvez maintenant modifier vos autoconsommations &agrave; tout
            moment de l'ann&eacute;e !
          </em>
        </p>
      </template>
    </template>
    <template #modal-footer>
      <b-button v-if="readOnly" block pill variant="primary" @click="edit">
        Modifier
      </b-button>
      <b-container v-else fluid>
        <b-row align-h="between">
          <b-col>
            <b-button block pill variant="outline-primary" @click="cancel">
              Annuler
            </b-button>
          </b-col>
          <b-col>
            <b-button block pill variant="primary" @click="validate">
              Enregistrer
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import GenericReadonlyComponent from "../inputs/GenericReadonlyComponent.vue";
// import CustomInputNumberComponent from "../inputs/CustomInputNumberComponent.vue";
import MontantComponent from "../MontantComponent.vue";
import SelectMotifComponent from "../inputs/SelectMotifComponent.vue";
import InputQuantiteAutoconsoComponent from "../inputs/InputQuantiteAutoconsoComponent.vue";
import InputCommentaireComponent from "../inputs/InputCommentaireComponent.vue";
import UtilsService from "../../services/utils.service";
export default {
  name: "AutoconsoEditionDialog",
  components: {
    GenericReadonlyComponent,
    // CustomInputNumberComponent,
    MontantComponent,
    SelectMotifComponent,
    InputQuantiteAutoconsoComponent,
    InputCommentaireComponent,
  },
  data: () => ({
    id: `autoconso-${uuidv4()}`,
    readOnly: false,
    culture: null,
    emblavement: null,
    autoconso: null,
    confirmationProvider: null,
    resolvePromise: null,
    rejectPromise: null,
  }),
  computed: {
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    libelleProduit() {
      return (
        this.culture?.libelleProduit ?? this.emblavement?.produit?.libelle ?? ""
      );
    },
    libelleVariete() {
      return (
        this.culture?.libelleVariete ?? this.emblavement?.variete?.libelle ?? ""
      );
    },
    surfaceCumulee() {
      if (this.culture?.id) {
        return this.$store.state.decl.parcelles
          .filter((p) => p.idCulture === this.culture.id)
          .reduce(
            (acc, next) => acc + next.surfaceEmblavee - next.surfaceRetiree,
            0.0
          );
      }
      if (this.emblavement?.id) {
        let parcelle = this.$store.getters["decl/parcelle"](
          this.emblavement.idParcelle
        );
        return parcelle
          ? parcelle.surfaceEmblavee - parcelle.surfaceRetiree
          : 0.0;
      }
      return 0.0;
    },
    quantiteProduite() {
      return (
        this.culture?.quantiteProduite ??
        this.emblavement?.quantiteProduite ??
        0.0
      );
    },
    rendementMoyen() {
      return this.surfaceCumulee > 0.0
        ? this.quantiteProduite / this.surfaceCumulee
        : 0.0;
    },
  },
  methods: {
    getNouvelleAutoconso() {
      return {
        quantiteAutoconsommee: 0.0,
        codeMotif: null,
        idCulture: this.culture?.id,
        idEmblavement: this.emblavement?.id,
      };
    },
    /**
     * OPTIONS
     * readOnly: Boolean = true pour visualisation, false pour édition (false par défaut)
     * culture: Object = objet culture si saisie à la culture
     * emblavement: Object = objet emblavement si saisie à l'emblavement
     * autoconso: Object = autoconso en cours de modification (culture, emblavement, ou nouvel objet)
     * confirmationProvider: async () => Boolean = fonction vérifiant si on change de mode de saisie
     *    A appeler dès qu'on passe en modification, pour obtenir la confirmation de l'utilisateur si besoin
     */
    async show(options) {
      this.readOnly = options?.readOnly === true;
      this.culture = options?.culture || null;
      this.emblavement = options?.emblavement || null;
      this.autoconso =
        UtilsService.deepCopy(this.culture?.autoconso) ||
        UtilsService.deepCopy(this.emblavement?.autoconso) ||
        this.getNouvelleAutoconso();

      this.confirmationProvider =
        options?.confirmationProvider ??
        function () {
          return true;
        };
      // Si on ouvre en modification, appeler le confirmationProvider
      // Si la réponse est non, ne pas ouvrir la popup
      if (!this.readOnly && !(await this.confirmationProvider())) {
        return;
      }

      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async edit() {
      // Si on passe en modification, appeler le confirmationProvider
      // Si la réponse est non, rester en visualisation
      if (await this.confirmationProvider()) {
        this.readOnly = false;
      }
    },
    async validate() {
      await this.$refs.selectMotif.validate();
      await this.$refs.inputQuantite?.validate();
      this.$refs["modal"].hide();
      this.resolvePromise(this.autoconso);
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(null);
    },
  },
};
</script>
