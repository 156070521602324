<template>
  <b-card
    class="mobile-card"
    header-bg-variant="light"
    v-b-toggle="`culture_${item.id}_collapse culture_${item.id}_button`"
  >
    <template #header>
      {{ item.libelleProduit }}
      <MelangeIcon v-if="item.isMelangeEspeces"></MelangeIcon>
      <div v-else class="label font-down">
        {{ item.libelleVariete }}
        <MelangeIcon v-if="item.isMelangeVarietes"></MelangeIcon>
      </div>
    </template>
    <b-container>
      <b-row align-v="center">
        <b-col
          cols="auto"
          class="bg-light bg-left font-up"
          style="width: 80px; border-radius: 0 !important"
        >
          <div class="w-100">
            <MontantComponent
              :value="quantiteAutoconsoCulture"
              :options="{
                minimumFractionDigits: undefined,
                maximumFractionDigits: 2,
              }"
            ></MontantComponent>
            t
          </div>
        </b-col>
        <b-col class="font-down">
          <div>
            <AnomalieIcon
              :anomaliesCulture="item.anomalies"
              :anomaliesAutoconso="item.autoconso.anomalies"
            ></AnomalieIcon>
            <BadgeComponent
              v-if="saisieCulture"
              :text="item.autoconso.motif.libelle"
              :variant="`list${item.autoconso.motif.ordre}`"
            ></BadgeComponent>
            <em v-else-if="saisieEmblavement">
              Autoconso &agrave; la parcelle
            </em>
            <template v-else>Saisir une autoconso</template>
          </div>
          <div class="label">
            <MontantComponent :value="item.quantiteProduite"></MontantComponent>
            t disponibles
          </div>
          <b-collapse
            :id="`culture_${item.id}_button`"
            class="label text-center"
            visible
          >
            <b-icon-chevron-down></b-icon-chevron-down>
            Voir les parcelles
          </b-collapse>
        </b-col>
        <b-col cols="auto" class="px-0" @click.stop>
          <ViewEditComponent @view="$emit('view')"></ViewEditComponent>
        </b-col>
      </b-row>
      <b-collapse
        :id="`culture_${item.id}_collapse`"
        style="border-top: 1px solid lightgrey"
      >
        <b-row v-for="emb in item.emblavements" :key="emb.id" align-v="center">
          <b-col
            cols="auto"
            class="bg-light bg-left font-up"
            style="width: 80px; border-radius: 0 !important"
          >
            <div v-if="saisieEmblavement" class="w-100">
              <MontantComponent
                :value="
                  emb.autoconso.enTotalite
                    ? emb.quantiteProduite
                    : emb.autoconso.quantiteAutoconsommee
                "
                :options="{
                  minimumFractionDigits: undefined,
                  maximumFractionDigits: 2,
                }"
              ></MontantComponent>
              t
            </div>
          </b-col>
          <b-col class="py-0 pt-1 font-down">
            <div class="rounded bg-light">
              {{ emb.parcelle.libelleParcelle }}
              (<MontantComponent
                :value="emb.quantiteProduite"
              ></MontantComponent>
              t)
            </div>
            <div v-if="item.isMelangeEspeces">
              {{ emb.produit.libelle }}
            </div>
            <div v-else-if="item.isMelangeVarietes">
              {{ emb.variete.libelle }} <MelangeIcon></MelangeIcon>
            </div>
            <div>
              <em v-if="saisieCulture">Autoconso &agrave; la culture</em>
              <BadgeComponent
                v-else-if="emb.autoconso.id"
                :text="emb.autoconso.motif.libelle"
                :variant="`list${emb.autoconso.motif.ordre}`"
              ></BadgeComponent>
              <template v-else>Saisir une autoconso</template>
            </div>
          </b-col>
          <b-col cols="auto" class="px-0" @click.stop>
            <ViewEditComponent
              @view="$emit('viewEmb', emb)"
            ></ViewEditComponent>
          </b-col>
        </b-row>
      </b-collapse>
    </b-container>
  </b-card>
</template>

<script>
import AnomalieIcon from "../controls/AnomalieIcon.vue";
import BadgeComponent from "../BadgeComponent.vue";
import MontantComponent from "../MontantComponent.vue";
import ViewEditComponent from "../controls/ViewEditComponent.vue";
export default {
  name: "AutoconsoCard",
  components: {
    AnomalieIcon,
    BadgeComponent,
    MontantComponent,
    ViewEditComponent,
  },
  props: ["item"],
  computed: {
    saisieCulture() {
      return !!this.item.autoconso.id;
    },
    saisieEmblavement() {
      return !!this.item.emblavements.find((e) => e.autoconso.id);
    },
    quantiteAutoconsoCulture() {
      // Autoconso à la culture
      if (this.item.autoconso.id) {
        return this.item.autoconso.enTotalite
          ? this.item.quantiteProduite
          : this.item.autoconso.quantiteAutoconsommee;
      }

      // Sinon somme des autoconsos emblavement
      return this.item.emblavements.reduce((acc, next) => {
        if (!next.autoconso.id) {
          return acc;
        }
        return (
          acc +
          (next.autoconso.enTotalite
            ? next.quantiteProduite
            : next.autoconso.quantiteAutoconsommee)
        );
      }, 0.0);
    },
  },
};
</script>
