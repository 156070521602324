<template>
  <GenericReadonlyComponent
    v-if="computedReadOnly"
    :label="label"
    :labelCols="labelCols"
    :quickEdit="quickEdit"
    @edit="$emit('edit')"
  >
    <slot>
      <MontantComponent :value="value"></MontantComponent>
      <span v-if="append"> {{ append }}</span>
    </slot>
  </GenericReadonlyComponent>
  <UnvalidatedInputNumberComponent
    v-else-if="rules == null"
    ref="unvalidatedInput"
    :label="label"
    :labelCols="labelCols"
    :size="size"
    :description="description"
    :prepend="prepend"
    :append="append"
    :disabled="disabled"
    :step="step"
    :value="value"
    @input="(value) => $emit('input', value)"
    @change="(value) => $emit('change', value)"
  >
    <template #prepend>
      <slot name="prepend"></slot>
    </template>
    <template #append>
      <slot name="append"></slot>
    </template>
  </UnvalidatedInputNumberComponent>
  <ValidatedInputNumberComponent
    v-else
    ref="validatedInput"
    :label="label"
    :labelCols="labelCols"
    :size="size"
    :description="description"
    :prepend="prepend"
    :append="append"
    :disabled="disabled"
    :rules="rules"
    :step="step"
    :value="value"
    @input="(value) => $emit('input', value)"
    @change="(value) => $emit('change', value)"
  >
    <template #prepend>
      <slot name="prepend"></slot>
    </template>
    <template #append>
      <slot name="append"></slot>
    </template>
  </ValidatedInputNumberComponent>
</template>

<script>
import GenericReadonlyComponent from "./GenericReadonlyComponent.vue";
import MontantComponent from "../MontantComponent.vue";
import UnvalidatedInputNumberComponent from "./UnvalidatedInputNumberComponent.vue";
import ValidatedInputNumberComponent from "./ValidatedInputNumberComponent.vue";
export default {
  name: "CustomInputNumberComponent",
  components: {
    GenericReadonlyComponent,
    MontantComponent,
    UnvalidatedInputNumberComponent,
    ValidatedInputNumberComponent,
  },
  props: {
    // valeur de l'input
    value: Number,
    // step de l'input
    step: {
      type: [Number, String],
      default: 0.1,
    },
    // prepend de l'input group
    prepend: String,
    // append de l'input group
    append: String,
    // propriété label du form-group
    label: {
      type: String,
      required: true,
    },
    // propriété label-cols du form group
    labelCols: [String, Number, Object],
    // propriété size du contrôle
    size: String,
    // propriété label-cols du form group
    description: String,
    // true pour affichage lecture seule (false par défaut)
    readOnly: [Boolean, String],
    // true pour autoriser l'édition rapide (false par défaut)
    quickEdit: [Boolean, String],
    // true pour griser le controle (false par défaut)
    disabled: [Boolean, String],
    // règles de validation à appliquer (aucune par défaut)
    rules: [String, Object],
  },
  computed: {
    computedReadOnly() {
      return this.readOnly === true || this.readOnly === "true";
    },
  },
  methods: {
    async focus() {
      if (this.computedReadOnly) {
        return;
      }
      if (this.rules == null) {
        this.$refs.unvalidatedInput.focus();
      } else {
        this.$refs.validatedInput.focus();
      }
    },
    async validate() {
      await this.$refs.validatedInput?.validate();
    },
  },
};
</script>
