<template>
  <div class="ml-5">
    <b-table
      :id="`autoconso-culture-${culture.id}`"
      table-class="mb-0"
      hover
      :fields="fields"
      :items="culture.emblavements"
      sort-by="parcelle.libelleParcelle"
      :sort-desc="false"
      @keydown.native.esc="cancel"
      @keydown.native.enter="onEnter"
    >
      <template #cell(quantiteProduite)="{ item }">
        <span :id="`quantite-emb-${item.id}`">
          <MontantComponent :value="item.quantiteProduite"></MontantComponent>
        </span>
        <QuantiteCollecteTooltip
          :target="`quantite-emb-${item.id}`"
          :quantitePrevisionnelle="item.quantitePrevisionnelle"
          :quantiteMoissonnee="item.quantiteMoissonnee"
          :quantiteAutoconsommee="item.autoconso.quantiteAutoconsommee"
        ></QuantiteCollecteTooltip>
      </template>
      <template #cell(motifAutoconso)="{ item }">
        <SelectMotifComponent
          :ref="`selectMotif_${item.id}`"
          label=""
          :readOnly="editing !== item.id"
          :societe="exploitationCourante.codeSociete"
          rules="required"
          v-model="item.autoconso.codeMotif"
        >
          <BadgeComponent
            v-if="item.autoconso.id"
            :text="item.autoconso.motif.libelle"
            :variant="`list${item.autoconso.motif.ordre}`"
            @click="editEmblavement(item)"
          ></BadgeComponent>
          <b-link v-else href="#" @click="editEmblavement(item)">
            <em v-if="culture.autoconso.id">Autoconso &agrave; la culture</em>
            <span v-else>Saisir une autoconso</span>
          </b-link>
        </SelectMotifComponent>
      </template>
      <template #cell(quantiteAutoconsommee)="{ item }">
        <template v-if="item.autoconso.codeMotif === 'NONE'">&nbsp;</template>
        <template v-else-if="editing !== item.id">
          <template v-if="!item.autoconso.id">&nbsp;</template>
          <template v-else>
            <AnomalieIcon
              v-if="item.autoconso.anomalies.includes('QUANTITE_INVALIDE')"
              classes="mr-3"
              :anomaliesAutoconso="['QUANTITE_INVALIDE']"
            ></AnomalieIcon>
            <b-link
              class="quick-edit-link"
              href="#"
              @click="editEmblavement(item)"
            >
              <span v-if="item.autoconso.enTotalite === true">Tout</span>
              <MontantComponent
                v-else
                :value="item.autoconso.quantiteAutoconsommee"
              ></MontantComponent>
            </b-link>
          </template>
        </template>
        <InputQuantiteAutoconsoComponent
          v-else
          :ref="`inputQuantite_${item.id}`"
          label=""
          :enTotalite="item.autoconso.enTotalite"
          @update:enTotalite="item.autoconso.enTotalite = $event"
          :quantiteProduite="item.quantiteProduite"
          v-model="item.autoconso.quantiteAutoconsommee"
        ></InputQuantiteAutoconsoComponent>
      </template>
      <template #cell(actions)="{ item }">
        <TableActionsComponent
          :editing="editing === item.id"
          :commentValue="item.autoconso ? item.autoconso.commentaire : null"
          :commentDisabled="!item.autoconso || !item.autoconso.id"
          :noDropdown="true"
          @cancel="cancel()"
          @validate="validate(item)"
          @comment="$emit('comment', item)"
          @view="$emit('view', item)"
          @edit="$emit('edit', item)"
        ></TableActionsComponent>
      </template>
    </b-table>
  </div>
</template>

<script>
import SelectMotifComponent from "./inputs/SelectMotifComponent.vue";
import MontantComponent from "./MontantComponent.vue";
import QuantiteCollecteTooltip from "./controls/QuantiteCollecteTooltip.vue";
import BadgeComponent from "./BadgeComponent.vue";
import AnomalieIcon from "./controls/AnomalieIcon.vue";
import InputQuantiteAutoconsoComponent from "./inputs/InputQuantiteAutoconsoComponent.vue";
import TableActionsComponent from "./controls/TableActionsComponent.vue";
export default {
  name: "AutoconsoDetailsComponent",
  components: {
    SelectMotifComponent,
    MontantComponent,
    QuantiteCollecteTooltip,
    BadgeComponent,
    AnomalieIcon,
    InputQuantiteAutoconsoComponent,
    TableActionsComponent,
  },
  props: ["culture", "confirmationProvider"],
  data: () => ({
    fields: [
      {
        key: "parcelle.libelleParcelle",
        label: "Parcelle",
      },
      {
        key: "produit.libelle",
        label: "Culture",
      },
      {
        key: "quantiteProduite",
        label: "Quantité produite (t)",
        thClass: "text-right",
        tdClass: "text-right",
      },
      {
        key: "motifAutoconso",
        label: "Motif d'autoconso",
      },
      {
        key: "quantiteAutoconsommee",
        label: "Quantité autoconso (t)",
        thClass: "text-right",
        tdClass: "text-right",
      },
      {
        key: "actions",
        label: "Actions",
        thStyle: { width: "120px" },
      },
    ],
    editing: null,
  }),
  computed: {
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
  },
  methods: {
    async editEmblavement(emblavement) {
      if (await this.confirmationProvider(this.culture)) {
        this.editing = emblavement.id;
        if (!emblavement.id) {
          return;
        }
        await this.$nextTick();
        this.$refs[`inputQuantite_${emblavement.id}`]?.focus();
        this.$refs[`selectMotif_${emblavement.id}`]?.focus();
      }
    },
    async onEnter() {
      let target = this.culture.emblavements.find((t) => t.id === this.editing);
      if (target) {
        this.validate(target);
      }
    },
    cancel() {
      this.editing = null;
      this.$emit("cancel");
    },
    async validate(emblavement) {
      await this.$refs[`selectMotif_${emblavement.id}`].validate();
      await this.$refs[`inputQuantite_${emblavement.id}`]?.validate();
      this.editing = null;
      this.$emit("validate", emblavement.autoconso);
    },
  },
};
</script>
