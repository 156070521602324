<template>
  <ValidationObserver ref="observer" slim>
    <ValidationProvider
      ref="provider"
      :rules="rules"
      v-slot="validationContext"
    >
      <b-form-group
        :id="`group-${id}`"
        :label="label"
        :label-for="`input-${id}`"
        :label-cols="computedLabelCols['xs']"
        :label-cols-sm="computedLabelCols['sm']"
        :label-cols-md="computedLabelCols['md']"
        :label-cols-lg="computedLabelCols['lg']"
        :label-cols-xl="computedLabelCols['xl']"
        :label-class="computedLabelClass"
        :description="description"
      >
        <b-input-group :prepend="prepend" :append="append">
          <template v-if="!!$slots.prepend" #prepend>
            <slot name="prepend"></slot>
          </template>
          <b-form-input
            ref="input"
            :id="`input-${id}`"
            :name="`input-${id}`"
            type="number"
            :size="size"
            :number="true"
            :step="step"
            :disabled="computedDisabled"
            :state="getValidationState(validationContext)"
            :aria-describedby="`feedback-${id}`"
            :value="computedValue"
            @input="(value) => onInput(sanitizeNumberValue(value))"
            @change="(value) => $emit('change', sanitizeNumberValue(value))"
          ></b-form-input>
          <template v-if="!!$slots.append" #append>
            <slot name="append"></slot>
          </template>
        </b-input-group>
        <b-form-invalid-feedback
          :id="`feedback-${id}`"
          :state="getValidationState(validationContext)"
        >
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
  </ValidationObserver>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import {
  computed as inputsComputed,
  methods as inputsMethods,
} from "../../services/inputs.service";
import {
  computed as validationComputed,
  methods as validationMethods,
} from "../../services/validation.service";
export default {
  name: "ValidatedInputNumberComponent",
  components: { ValidationObserver, ValidationProvider },
  props: {
    // valeur de l'input
    value: Number,
    // step de l'input
    step: {
      type: [Number, String],
      default: 0.1,
    },
    // prepend de l'input group
    prepend: String,
    // append de l'input group
    append: String,
    // propriété label du form-group
    label: {
      type: String,
      required: true,
    },
    // propriété label-cols du form group
    labelCols: [String, Number, Object],
    // propriété size du contrôle
    size: String,
    // propriété label-cols du form group
    description: String,
    // true pour griser le controle (false par défaut)
    disabled: [Boolean, String],
    // règles de validation à appliquer (aucune par défaut)
    rules: [String, Object],
  },
  data: () => ({
    id: uuidv4(),
  }),
  computed: {
    ...inputsComputed,
    ...validationComputed,
    computedValue() {
      return this.value == null ? null : Math.round(this.value * 100.0) / 100.0;
    },
  },
  methods: {
    ...inputsMethods,
    ...validationMethods,
  },
};
</script>
